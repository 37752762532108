import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import "./Home.css";
import googleLogo from "./google-color-icon.png";
import carousel1 from "./link-mail.png";
import carousel2 from "./read-mail.png"; // Add your image paths here
import carousel3 from "./fetch-invoice.png"; // Add your image paths here
import carousel4 from "./invoice-managed.png"; // Add your image paths here
import kuttlLogo from "./kuttl-logo.jpg";
//const backendBaseUrl = env.BACKEND_URL;
// const backendBaseUrl = 'https://beta-app-backend-api-server.onrender.com'
//const backendBaseUrl = 'https://kuttl-app.kuttl.one'
//const backendBaseUrl = 'http://localhost:3000'
//const backendBaseUrl = 'http://localhost:8080'
const backendBaseUrl = process.env.REACT_APP_BACKEND_URL;
function Home() {
  // Extract the session ID from the URL's query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get("session-id");
  const handleLogin = async () => {
    try {
      // Fetch the session ID from the backend
      // const sessionResponse = await fetch(`${backendBaseUrl}/user_app/gmail-auth/google`, { // use axios
      //   method: 'GET',
      //   headers: {
      //     // Ensure any required headers are included, such as Authorization if needed
      //     'x-session-id-authorization': sessionId,
      //   },
      // });

      // if (!sessionResponse.ok) {
      //   throw new Error('Failed to fetch session ID');
      // }

      // const { sessionId } = await sessionResponse.json();

      // // Use the fetched session ID to redirect to Google OAuth flow
      window.location.href = `${backendBaseUrl}/user_app/secure-vault/link_mail/google?session-id=${sessionId}`;
    } catch (error) {
      console.error("Error during login, kindly check:", error);
    }
  };

  return (
    <div className="outer-container">
      <div className="main-container">
        <div className="left-side">
          <img src={kuttlLogo} alt="Kuttl logo" className="app-logo" />
          <h1 className="main-heading">Kuttl Secure Gateway</h1>
          <button onClick={handleLogin} className="google-sign-in-btn">
            <img src={googleLogo} alt="Google logo" className="google-icon" />
            Continue with Google
          </button>
        </div>
        <div className="right-side">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={2000}
            showArrows={false}
            showStatus={false}
            showIndicators={true}
          >
            <div>
              <img
                src={carousel1}
                alt="Illustration 1"
                className="carousel-image"
              />
            </div>
            <div>
              <img
                src={carousel2}
                alt="Illustration 2"
                className="carousel-image"
              />
            </div>
            <div>
              <img
                src={carousel3}
                alt="Illustration 3"
                className="carousel-image"
              />
            </div>
            <div>
              <img
                src={carousel4}
                alt="Illustration 4"
                className="carousel-image"
              />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Home;
