import React, { useEffect, useState } from "react";
import "./Success.css";
import SuccessAnimation from "./SuccessAnimation";

const SuccessPage = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (countdown === 0) {
      window.location.replace(
        `mykuttlapp://?session-id=b409b862939effb4a9dd8e1c2fac54cb`
      );
      return;
    }

    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  return (
    <div className="success-container">
      <div className="success-content">
        <h1>Your email has been linked!</h1>
        <p>
          Your linked invoices will start to be processed. In the meantime,
          continue managing your existing purchases.
        </p>
        <p>Redirecting in {countdown}...</p>
      </div>
      <div className="svg-container">
        <SuccessAnimation />
      </div>
    </div>
  );
};

export default SuccessPage;
