import React, { useEffect } from "react";
import "./SuccessAnimation.css";

const SuccessAnimation = () => {
  useEffect(() => {
    document
      .getElementById("freepik_stories-success")
      .classList.add("animated");
  }, []);

  return (
    <div className="success-animation-container">
      <svg
        id="freepik_stories-success"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 500 500"
      >
        <g id="freepik--background-simple--inject-348" className="animable">
          <ellipse
            cx="247"
            cy="434.29"
            rx="145.04"
            ry="18.11"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeMiterlimit: 10,
              strokeWidth: "0.25px",
              transformOrigin: "247px 434.29px",
            }}
            id="elrno4qlvkfpc"
            className="animable"
          ></ellipse>
          {/* Include all other SVG elements with correct style object */}
        </g>
        <g
          id="freepik--Icons--inject-348"
          className="animable icon-spin"
          style={{
            transformOrigin: "244.076px 204.89px",
          }}
        >
          {/* Include specific SVG elements for icons here */}
        </g>
        <g
          id="freepik--Character--inject-348"
          className="animable"
          style={{
            transformOrigin: "234.715px 292.839px",
          }}
        >
          {/* Include specific SVG elements for character here */}
        </g>
        <g
          id="freepik--Floor--inject-348"
          class="animable"
          style={{ transformOrigin: "247px 434.29px" }}
        >
          <ellipse
            cx="247"
            cy="434.29"
            rx="145.04"
            ry="18.11"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeMiterlimit: 10,
              strokeWidth: "0.25px",
              transformOrigin: "247px 434.29px",
            }}
            id="elrno4qlvkfpc"
            className="animable"
          ></ellipse>
          <ellipse
            cx="247"
            cy="432.5"
            rx="118.63"
            ry="15.61"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeMiterlimit: 10,
              strokeWidth: "0.5px",
              transformOrigin: "247px 432.5px",
            }}
            id="el3axjv7ws6pf"
            className="animable"
          ></ellipse>
          <ellipse
            cx="247"
            cy="432.5"
            rx="91.64"
            ry="12.06"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeMiterlimit: 10,
              transformOrigin: "247px 432.5px",
            }}
            id="elkc9ru1sqjw"
            className="animable"
          ></ellipse>
          <ellipse
            cx="248.02"
            cy="432.1"
            rx="75.24"
            ry="7.9"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "248.02px 432.1px",
            }}
            id="el5i1leupbhxh"
            className="animable"
          ></ellipse>
          <g id="el5hzhpq3s3q5">
            <ellipse
              cx="248.02"
              cy="432.1"
              rx="75.24"
              ry="7.9"
              style={{
                fill: "rgb(255, 255, 255)",
                opacity: 0.5,
                transformOrigin: "248.02px 432.1px",
              }}
              className="animable"
            ></ellipse>
          </g>
        </g>
        <g
          id="freepik--Lines--inject-348"
          className="animable"
          style={{ transformOrigin: "246.161px 217.878px" }}
        >
          <circle
            cx="247.02"
            cy="220.18"
            r="135.53"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeDasharray: "2, 3",
              transformOrigin: "247.02px 220.18px",
            }}
            id="elg962g4bze5p"
            className="animable"
          ></circle>
          <path
            d="M197.7,357.18c-20.81,0-38.12-6.41-50.52-18.81-36.64-36.64-17.92-115,41.71-174.6,28.88-28.87,62.93-49.12,95.88-57s61-2.47,78.72,15.29c15.41,15.42,21.65,39.22,17.56,67l-.49-.07c4.06-27.64-2.13-51.29-17.43-66.59-17.63-17.64-45.42-23-78.24-15.16s-66.83,28.06-95.64,56.87C129.8,223.56,111.09,301.57,147.53,338c14.51,14.51,35.81,20.75,61.6,18.05l.05.5A110.73,110.73,0,0,1,197.7,357.18Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "255.353px 230.21px",
            }}
            id="el5s4f47q235j"
            className="animable"
          ></path>
          <path
            d="M305.44,334.22c-34.36,0-74.44-13.74-110.36-40.62-66.23-49.56-93.6-125.3-61-168.84,14.29-19.1,38.59-29.68,68.41-29.78v.5c-29.66.1-53.81,10.6-68,29.58-32.41,43.32-5.09,118.75,60.92,168.14s146.08,54.33,178.5,11c13.42-17.95,17.09-41.56,10.6-68.28l.49-.12c6.52,26.88,2.82,50.63-10.69,68.69C359.37,324.43,334.44,334.21,305.44,334.22Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "254.232px 214.6px",
            }}
            id="el6d2u57eaw2i"
            className="animable"
          ></path>
          <path
            d="M183.7,276a215.13,215.13,0,0,1-31.45-2.16c-31-4.61-50.31-16.08-54.23-32.3s8-35.23,33.51-53.5S193,154.45,232.89,144.81s78.93-12.42,109.9-7.82,50.32,16.07,54.24,32.3-8,35.22-33.51,53.49S302.06,256.36,262.16,266C234.83,272.6,207.9,276,183.7,276ZM311.39,135.36c-23.64,0-50.5,3.2-78.39,9.94-39.83,9.62-75.77,24.94-101.18,43.13s-37.18,37-33.31,53,23,27.35,53.82,31.92,69.88,1.81,109.71-7.81l.06.24-.06-.24c82.17-19.85,142.51-63,134.5-96.11C391.25,147.51,357.43,135.36,311.39,135.36Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "247.525px 205.413px",
            }}
            id="elrx78he0hzsr"
            className="animable"
          ></path>
          <path
            d="M213.42,372.34a26.13,26.13,0,0,1-6-.71c-16.23-3.92-27.6-23.59-32-55.39s-1.4-71.72,8.49-112.67,25.47-77.92,43.88-104.12,37.54-38.58,53.77-34.66,27.6,23.59,32,55.38,1.39,71.73-8.5,112.68S279.55,310.77,261.14,337C244.94,360,228.24,372.34,213.42,372.34ZM275.48,64.58c-32.24,0-72,59.69-91.16,139.11C164,288,174.34,363.13,207.49,371.14c16,3.87,34.92-8.37,53.24-34.46s33.93-63.07,43.81-104l.24.06-.24-.06c20.37-84.33,10-159.45-23.17-167.45A24.84,24.84,0,0,0,275.48,64.58Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "244.489px 218.209px",
            }}
            id="elbktzn3nqxte"
            className="animable"
          ></path>
          <path
            d="M264.85,330.28a2.5,2.5,0,1,0,0-3.54A2.52,2.52,0,0,0,264.85,330.28Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "266.62px 328.51px",
            }}
            id="elu3rhmbs9j5"
            className="animable"
          ></path>
          <path
            d="M266.62,331.26a2.77,2.77,0,1,1,1.94-.81A2.72,2.72,0,0,1,266.62,331.26Zm0-5a2.25,2.25,0,1,0,1.59,3.84,2.25,2.25,0,0,0,0-3.18A2.23,2.23,0,0,0,266.62,326.26Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "266.603px 328.49px",
            }}
            id="elr5rzhyxsln"
            className="animable"
          ></path>
          <path
            d="M172.18,276.87a2.5,2.5,0,1,0,0-3.54A2.5,2.5,0,0,0,172.18,276.87Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "173.946px 275.1px",
            }}
            id="eliy0g13jw4l"
            className="animable"
          ></path>
          <path
            d="M173.94,277.85A2.73,2.73,0,0,1,172,277h0a2.74,2.74,0,1,1,1.94.81Zm0-5a2.25,2.25,0,0,0-1.59,3.84h0a2.3,2.3,0,0,0,3.18,0,2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "173.945px 275.09px",
            }}
            id="elbbebbn2ugr7"
            className="animable"
          ></path>
          <path
            d="M139,235.83a2.5,2.5,0,1,0,0-3.53A2.5,2.5,0,0,0,139,235.83Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "140.771px 234.065px",
            }}
            id="eljyl51tn0ah"
            className="animable"
          ></path>
          <path
            d="M140.76,236.81a2.75,2.75,0,1,1,1.94-.8A2.75,2.75,0,0,1,140.76,236.81Zm0-5a2.24,2.24,0,1,0,1.59.65A2.26,2.26,0,0,0,140.76,231.82Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "140.761px 234.06px",
            }}
            id="el9dp1jkn0t2d"
            className="animable"
          ></path>
          <path
            d="M122.11,196.17a2.5,2.5,0,1,0,0-3.54A2.49,2.49,0,0,0,122.11,196.17Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "123.873px 194.4px",
            }}
            id="elnw2noa5xl2p"
            className="animable"
          ></path>
          <path
            d="M123.87,197.15a2.77,2.77,0,0,1-1.94-.8h0a2.75,2.75,0,1,1,3.89,0A2.77,2.77,0,0,1,123.87,197.15ZM122.28,196a2.25,2.25,0,1,0,0-3.18,2.25,2.25,0,0,0,0,3.18Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "123.875px 194.403px",
            }}
            id="el4nnhmc3hz39"
            className="animable"
          ></path>
          <path
            d="M128.39,270.39a2.5,2.5,0,1,0,0-3.54A2.5,2.5,0,0,0,128.39,270.39Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "130.156px 268.62px",
            }}
            id="elef48zuo264o"
            className="animable"
          ></path>
          <path
            d="M130.16,271.37a2.77,2.77,0,0,1-1.95-.8h0a2.76,2.76,0,0,1,0-3.89,2.75,2.75,0,1,1,1.95,4.69Zm0-5a2.25,2.25,0,0,0-1.59,3.84h0a2.3,2.3,0,0,0,3.18,0,2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "130.159px 268.62px",
            }}
            id="el2lp7fi5xe5f"
            className="animable"
          ></path>
          <path
            d="M197.7,156.31a2.5,2.5,0,1,0,0-3.53A2.49,2.49,0,0,0,197.7,156.31Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "199.468px 154.545px",
            }}
            id="ele0pirkm119d"
            className="animable"
          ></path>
          <path
            d="M199.47,157.29a2.75,2.75,0,1,1,1.94-4.69,2.75,2.75,0,0,1-1.94,4.69Zm0-5a2.22,2.22,0,0,0-1.59.65,2.27,2.27,0,1,0,1.59-.65Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "199.461px 154.54px",
            }}
            id="eltnd8xhhpkvs"
            className="animable"
          ></path>
          <path
            d="M308.64,104.67a2.5,2.5,0,1,0,0-3.53A2.49,2.49,0,0,0,308.64,104.67Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "310.408px 102.905px",
            }}
            id="elg2qrinndlqm"
            className="animable"
          ></path>
          <path
            d="M310.4,105.65a2.72,2.72,0,0,1-1.94-.8h0a2.75,2.75,0,1,1,3.89-3.89,2.76,2.76,0,0,1,0,3.89A2.73,2.73,0,0,1,310.4,105.65Zm0-5a2.24,2.24,0,0,0-2.24,2.25,2.24,2.24,0,0,0,.65,1.59h0a2.31,2.31,0,0,0,3.19,0,2.26,2.26,0,0,0,0-3.18A2.29,2.29,0,0,0,310.4,100.65Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "310.402px 102.901px",
            }}
            id="eltvaraaufkys"
            className="animable"
          ></path>
          <path
            d="M313.35,136.87a2.5,2.5,0,1,0,0-3.53A2.49,2.49,0,0,0,313.35,136.87Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "315.118px 135.105px",
            }}
            id="el2bbtjndhvtv"
            className="animable"
          ></path>
          <path
            d="M315.12,137.85a2.73,2.73,0,0,1-1.95-.8h0a2.76,2.76,0,0,1,0-3.89,2.75,2.75,0,1,1,1.95,4.69Zm-1.59-1.16a2.3,2.3,0,0,0,3.18,0,2.23,2.23,0,0,0,.66-1.59,2.27,2.27,0,0,0-.66-1.59,2.3,2.3,0,0,0-3.18,0,2.25,2.25,0,0,0,0,3.18Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "315.119px 135.1px",
            }}
            id="elgg6pr5b83tp"
            className="animable"
          ></path>
          <path
            d="M297.25,256.25a2.5,2.5,0,1,0,0-3.53A2.49,2.49,0,0,0,297.25,256.25Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "299.018px 254.485px",
            }}
            id="elgfn8u65m13"
            className="animable"
          ></path>
          <path
            d="M299,257.23a2.77,2.77,0,0,1-1.95-.8h0a2.76,2.76,0,1,1,1.95.8Zm-1.59-1.15a2.25,2.25,0,1,0,0-3.19,2.26,2.26,0,0,0,0,3.19Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "298.993px 254.47px",
            }}
            id="ellz7tgt0g7hf"
            className="animable"
          ></path>
          <path
            d="M186.7,357.76a2.5,2.5,0,1,0,0-3.53A2.5,2.5,0,0,0,186.7,357.76Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "188.471px 355.995px",
            }}
            id="elx7cgvibvsgm"
            className="animable"
          ></path>
          <path
            d="M188.47,358.75a2.72,2.72,0,0,1-1.94-.81h0a2.76,2.76,0,0,1,0-3.89,2.75,2.75,0,1,1,1.94,4.7Zm-1.59-1.16a2.25,2.25,0,0,0,3.18,0,2.25,2.25,0,0,0,0-3.18,2.3,2.3,0,0,0-3.18,0,2.25,2.25,0,0,0,0,3.18Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "188.474px 356px",
            }}
            id="elzr4kqp09fqm"
            className="animable"
          ></path>
          <path
            d="M376.77,150.42a2.5,2.5,0,1,0,0-3.53A2.49,2.49,0,0,0,376.77,150.42Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "378.538px 148.655px",
            }}
            id="el45e2abv0o4"
            className="animable"
          ></path>
          <path
            d="M378.54,151.4a2.73,2.73,0,0,1-2-.8,2.76,2.76,0,1,1,3.89,0A2.72,2.72,0,0,1,378.54,151.4Zm0-5a2.25,2.25,0,0,0-1.59,3.84,2.3,2.3,0,0,0,3.18,0,2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "378.485px 148.641px",
            }}
            id="elyeto8h5i3br"
            className="animable"
          ></path>
          <g id="el9gf3bzyrn56">
            <rect
              x="213.43"
              y="85.06"
              width="0.5"
              height="5.96"
              style={{
                fill: "rgb(146, 227, 169)",
                transformOrigin: "213.68px 88.04px",
                transform: "rotate(-8.13deg)",
              }}
              className="animable"
            ></rect>
          </g>
          <path
            d="M294.62,356.51a110.66,110.66,0,0,1-11.48-.61l.05-.5c25.79,2.7,47.09-3.54,61.6-18,36.44-36.44,17.72-114.45-41.72-173.89-28.81-28.81-62.78-49-95.64-56.88s-60.61-2.47-78.25,15.16c-15.3,15.3-21.48,39-17.42,66.6l-.49.07c-4.09-27.81,2.14-51.61,17.56-67,17.76-17.76,45.72-23.2,78.72-15.3s67,28.14,95.87,57c59.64,59.64,78.36,138,41.72,174.6C332.74,350.1,315.42,356.51,294.62,356.51Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "236.967px 229.567px",
            }}
            id="el5y2yuqbpi9t"
            className="animable"
          ></path>
          <path
            d="M186.75,333.62c-30,0-54.23-10.43-68.71-29.78-13.52-18.07-17.21-41.82-10.69-68.69l.49.12c-6.49,26.72-2.83,50.33,10.6,68.27,15.69,21,42.89,31.39,76.58,29.32S265,316.47,296.94,292.53c66-49.39,93.33-124.82,60.91-168.14-14.19-19-38.34-29.47-68-29.57v-.5c29.82.1,54.11,10.67,68.4,29.77,32.59,43.54,5.22,119.29-61,168.84-32.07,24-68.36,38.36-102.19,40.43Q190.84,333.61,186.75,333.62Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "238.104px 213.97px",
            }}
            id="elcp8qek5tm6q"
            className="animable"
          ></path>
          <path
            d="M308.62,275.32c-24.21,0-51.13-3.39-78.47-10-39.89-9.64-75.89-25-101.35-43.21s-37.43-37.27-33.51-53.5,23.18-27.7,54.23-32.3,70-1.81,109.91,7.82,75.9,25,101.36,43.22,37.42,37.27,33.5,53.49-23.18,27.7-54.23,32.3A214.85,214.85,0,0,1,308.62,275.32ZM181,134.66a215.35,215.35,0,0,0-31.37,2.15c-30.84,4.58-49.95,15.92-53.82,31.93s8,34.83,33.31,53,61.34,33.51,101.18,43.13c82.17,19.85,155.53,9,163.54-24.11,3.87-16-8-34.82-33.31-53s-61.35-33.51-101.19-43.13C232,138,205.13,134.66,181,134.66Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "244.79px 204.734px",
            }}
            id="elgpbudv0oibn"
            className="animable"
          ></path>
          <path
            d="M278.89,371.67c-14.81,0-31.51-12.3-47.72-35.36-18.4-26.21-34-63.19-43.88-104.13s-12.9-81-8.49-112.67,15.8-51.46,32-55.38,35.32,8.38,53.77,34.65,34,63.18,43.88,104.13,12.9,81,8.49,112.67S301.17,367,285,371A25.62,25.62,0,0,1,278.89,371.67Zm-62-307.76a25.13,25.13,0,0,0-5.94.7c-16,3.87-27.25,23.39-31.64,55s-1.4,71.6,8.48,112.48,25.43,77.81,43.8,104,37.23,38.32,53.25,34.45C318,362.47,328.36,287.35,308,203c-9.87-40.88-25.43-77.8-43.8-104C248.09,76.14,231.53,63.91,216.88,63.91Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "247.87px 217.544px",
            }}
            id="el9533bih8zim"
            className="animable"
          ></path>
          <path
            d="M227.46,329.61a2.5,2.5,0,1,1,0-3.53A2.49,2.49,0,0,1,227.46,329.61Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "225.692px 327.845px",
            }}
            id="el5ptb9oote6j"
            className="animable"
          ></path>
          <path
            d="M225.7,330.59a2.77,2.77,0,0,1-1.95-.8,2.76,2.76,0,1,1,3.89,0h0A2.75,2.75,0,0,1,225.7,330.59Zm0-5a2.25,2.25,0,0,0-1.59.66,2.25,2.25,0,1,0,3.18,3.18,2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "225.695px 327.831px",
            }}
            id="ellnqiyc5yzym"
            className="animable"
          ></path>
          <path
            d="M320.14,276.2a2.5,2.5,0,1,1,0-3.53A2.49,2.49,0,0,1,320.14,276.2Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "318.372px 274.435px",
            }}
            id="el8mc2ql7cgxa"
            className="animable"
          ></path>
          <path
            d="M318.38,277.19a2.76,2.76,0,1,1,1.94-.81h0A2.73,2.73,0,0,1,318.38,277.19Zm0-5a2.26,2.26,0,0,0-1.6.66,2.22,2.22,0,0,0-.65,1.59,2.26,2.26,0,0,0,.65,1.59,2.3,2.3,0,0,0,3.18,0h0a2.25,2.25,0,0,0,0-3.18A2.21,2.21,0,0,0,318.38,272.19Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "318.367px 274.43px",
            }}
            id="elhi2ayc1dq7l"
            className="animable"
          ></path>
          <path
            d="M353.32,235.17a2.5,2.5,0,1,1,0-3.54A2.5,2.5,0,0,1,353.32,235.17Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "351.554px 233.4px",
            }}
            id="elk9eo5q7q4a"
            className="animable"
          ></path>
          <path
            d="M351.56,236.15a2.77,2.77,0,1,1,1.94-.81h0A2.73,2.73,0,0,1,351.56,236.15Zm0-5a2.21,2.21,0,0,0-1.59.66,2.25,2.25,0,0,0,3.18,3.18h0a2.25,2.25,0,0,0,0-3.18A2.22,2.22,0,0,0,351.56,231.15Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "351.543px 233.38px",
            }}
            id="elz8wztujq96"
            className="animable"
          ></path>
          <path
            d="M370.21,195.5a2.5,2.5,0,1,1,0-3.53A2.49,2.49,0,0,1,370.21,195.5Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "368.442px 193.735px",
            }}
            id="elx9b21jb4tmr"
            className="animable"
          ></path>
          <path
            d="M368.44,196.49a2.76,2.76,0,1,1,1.95-.81h0A2.74,2.74,0,0,1,368.44,196.49Zm0-5a2.22,2.22,0,0,0-1.59.66,2.25,2.25,0,0,0,0,3.18,2.3,2.3,0,0,0,3.18,0,2.25,2.25,0,0,0,0-3.18A2.21,2.21,0,0,0,368.44,191.49Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "368.437px 193.73px",
            }}
            id="el6ac334vskw"
            className="animable"
          ></path>
          <path
            d="M363.93,269.72a2.5,2.5,0,1,1,0-3.53A2.5,2.5,0,0,1,363.93,269.72Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "362.159px 267.955px",
            }}
            id="elkzvtypmy99"
            className="animable"
          ></path>
          <path
            d="M362.16,270.7a2.75,2.75,0,0,1-2.75-2.74,2.75,2.75,0,1,1,4.69,1.94h0A2.71,2.71,0,0,1,362.16,270.7Zm0-5a2.25,2.25,0,0,0-2.25,2.25,2.25,2.25,0,0,0,3.84,1.59h0a2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "362.16px 267.951px",
            }}
            id="el7y3f3e80m8i"
            className="animable"
          ></path>
          <path
            d="M294.62,155.65a2.5,2.5,0,1,1,0-3.54A2.52,2.52,0,0,1,294.62,155.65Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "292.85px 153.88px",
            }}
            id="elzf20ni52q3g"
            className="animable"
          ></path>
          <path
            d="M292.85,156.63a2.7,2.7,0,0,1-1.94-.81,2.74,2.74,0,0,1,0-3.89,2.69,2.69,0,0,1,1.94-.8,2.72,2.72,0,0,1,1.94.8,2.76,2.76,0,0,1-1.94,4.7Zm0-5a2.21,2.21,0,0,0-1.59.66,2.25,2.25,0,0,0,0,3.18,2.3,2.3,0,0,0,3.18,0h0a2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "292.843px 153.88px",
            }}
            id="elv48qii7btrh"
            className="animable"
          ></path>

          <path
            d="M183.68,104a2.5,2.5,0,1,1,0-3.54A2.52,2.52,0,0,1,183.68,104Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "181.91px 102.23px",
            }}
            id="eln83kgapkcgs"
            className="animable"
          />
          <path
            d="M181.91,105a2.75,2.75,0,1,1,2-.81A2.73,2.73,0,0,1,181.91,105Zm0-5a2.22,2.22,0,0,0-1.59.66,2.25,2.25,0,0,0,0,3.18,2.3,2.3,0,0,0,3.18,0,2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "181.96px 102.251px",
            }}
            id="elbwgkbs8edh"
            className="animable"
          />
          <path
            d="M179,136.21a2.5,2.5,0,1,1,0-3.54A2.52,2.52,0,0,1,179,136.21Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "177.23px 134.44px",
            }}
            id="elmftwwyfd2rs"
            className="animable"
          />
          <path
            d="M177.2,137.19a2.75,2.75,0,1,1,1.94-.81A2.72,2.72,0,0,1,177.2,137.19Zm0-5a2.25,2.25,0,1,0,1.59.66A2.23,2.23,0,0,0,177.2,132.19Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "177.191px 134.44px",
            }}
            id="el0hg097hozit"
            className="animable"
          />
          <path
            d="M195.07,255.59a2.5,2.5,0,1,1,0-3.54A2.52,2.52,0,0,1,195.07,255.59Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "193.3px 253.82px",
            }}
            id="ela9zll9662bf"
            className="animable"
          />
          <path
            d="M193.3,256.57a2.77,2.77,0,1,1,1.94-.81h0A2.72,2.72,0,0,1,193.3,256.57Zm0-5a2.21,2.21,0,0,0-1.59.66,2.25,2.25,0,0,0,3.18,3.18,2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "193.283px 253.8px",
            }}
            id="elhu8im76h95h"
            className="animable"
          />
          <path
            d="M305.61,357.1a2.5,2.5,0,1,1,0-3.54A2.49,2.49,0,0,1,305.61,357.1Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "303.847px 355.33px",
            }}
            id="el1lscilwwdp1"
            className="animable"
          />
          <path
            d="M303.84,358.08a2.75,2.75,0,1,1,2-4.69,2.76,2.76,0,0,1,0,3.89h0A2.77,2.77,0,0,1,303.84,358.08Zm0-5a2.25,2.25,0,0,0-1.59,3.84,2.3,2.3,0,0,0,3.18,0h0a2.25,2.25,0,0,0-1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "303.892px 355.33px",
            }}
            id="elq7g98d60lwh"
            className="animable"
          />
          <path
            d="M115.55,149.75a2.5,2.5,0,1,1,0-3.53A2.5,2.5,0,0,1,115.55,149.75Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "113.779px 147.985px",
            }}
            id="el5vdwb3skmli"
            className="animable"
          />
          <path
            d="M113.78,150.74a2.77,2.77,0,1,1,1.94-.81A2.72,2.72,0,0,1,113.78,150.74Zm0-5a2.23,2.23,0,0,0-1.59.66,2.25,2.25,0,0,0,0,3.18,2.25,2.25,0,1,0,1.59-3.84Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "113.763px 147.97px",
            }}
            id="elg16b1sburo7"
            className="animable"
          />
        </g>
        <g
          id="freepik--Icons--inject-348"
          className="animable animator-active"
          style={{ transformOrigin: "244.076px 204.89px" }}
        >
          <path
            d="M151.89,329.51h0a18.09,18.09,0,0,1-18.09-18.09h0a18.09,18.09,0,0,1,18.09-18.09h0A18.09,18.09,0,0,1,170,311.42h0A18.09,18.09,0,0,1,151.89,329.51Z"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "151.9px 311.42px",
            }}
            id="eltaqugl98ndh"
            className="animable"
          />
          <path
            d="M151.89,303.29c2.65,0,3,0,4,.06a5.26,5.26,0,0,1,1.84.34,3.27,3.27,0,0,1,1.88,1.88,5.26,5.26,0,0,1,.34,1.84c0,1,.06,1.36.06,4s0,3-.06,4a5.32,5.32,0,0,1-.34,1.84,3.27,3.27,0,0,1-1.88,1.88,5.26,5.26,0,0,1-1.84.34c-1.05.05-1.36.06-4,.06s-3,0-4-.06a5.32,5.32,0,0,1-1.84-.34,3.08,3.08,0,0,1-1.14-.74,3.21,3.21,0,0,1-.74-1.14,5.32,5.32,0,0,1-.34-1.84c0-1.05-.06-1.36-.06-4s0-3,.06-4a5.26,5.26,0,0,1,.34-1.84,3.21,3.21,0,0,1,.74-1.14,3.08,3.08,0,0,1,1.14-.74,5.32,5.32,0,0,1,1.84-.34c1.05-.05,1.36-.06,4-.06m0-1.79c-2.69,0-3,0-4.09.06a7.44,7.44,0,0,0-2.4.46,4.92,4.92,0,0,0-1.76,1.15,4.81,4.81,0,0,0-1.14,1.75,7.17,7.17,0,0,0-.47,2.41c0,1.06-.06,1.4-.06,4.09s0,3,.06,4.09a7.28,7.28,0,0,0,.47,2.41,4.81,4.81,0,0,0,1.14,1.75,4.92,4.92,0,0,0,1.76,1.15,7.44,7.44,0,0,0,2.4.46c1.06.05,1.4.06,4.09.06s3,0,4.09-.06a7.45,7.45,0,0,0,2.41-.46,5,5,0,0,0,1.75-1.15,4.84,4.84,0,0,0,1.15-1.75,7.57,7.57,0,0,0,.46-2.41c.05-1.06.06-1.4.06-4.09s0-3-.06-4.09a7.45,7.45,0,0,0-.46-2.41,4.84,4.84,0,0,0-1.15-1.75,5,5,0,0,0-1.75-1.15,7.45,7.45,0,0,0-2.41-.46c-1.06-.05-1.39-.06-4.09-.06"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "151.89px 311.42px",
            }}
            id="elrzjdtrh1m5l"
            className="animable"
          />
          <path
            d="M151.89,306.33a5.09,5.09,0,1,0,5.09,5.09,5.09,5.09,0,0,0-5.09-5.09m0,8.39a3.31,3.31,0,1,1,3.31-3.3,3.3,3.3,0,0,1-3.31,3.3"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "151.89px 311.42px",
            }}
            id="elac33d1akrdv"
            className="animable"
          />
          <path
            d="M158.38,306.13a1.19,1.19,0,1,1-2.38,0,1.19,1.19,0,1,1,2.38,0"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "157.19px 306.13px",
            }}
            id="elauhkqnhewyp"
            className="animable"
          />
          <path
            d="M310,117.76h0a18.09,18.09,0,0,1-18.09-18.09h0A18.08,18.08,0,0,1,310,81.59h0a18.07,18.07,0,0,1,18.08,18.08h0A18.08,18.08,0,0,1,310,117.76Z"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "309.995px 99.675px",
            }}
            id="elrj38nrtg2g"
            className="animable"
          />
          <path
            d="M307.16,110.61h4.4v-11h3.08l.32-3.69h-3.4v-2.1c0-.87.18-1.22,1-1.22H315V88.74h-3c-3.28,0-4.76,1.44-4.76,4.2v3h-2.29v3.74h2.29Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "309.975px 99.675px",
            }}
            id="el59inrat110d"
            className="animable"
          />
          <path
            d="M134.59,152.24h0a18.09,18.09,0,0,1-18.09-18.09h0a18.09,18.09,0,0,1,18.09-18.08h0a18.09,18.09,0,0,1,18.08,18.08h0A18.09,18.09,0,0,1,134.59,152.24Z"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "134.585px 134.155px",
            }}
            id="eldakmac9crga"
            className="animable"
          />
          <path
            d="M125,140.21a11.24,11.24,0,0,0,17.3-10,8,8,0,0,0,2-2,8.2,8.2,0,0,1-2.27.62,4,4,0,0,0,1.74-2.19,8,8,0,0,1-2.51,1,4,4,0,0,0-6.74,3.61,11.24,11.24,0,0,1-8.15-4.13,4,4,0,0,0,1.23,5.28,3.94,3.94,0,0,1-1.8-.5,4,4,0,0,0,3.17,3.93,4.08,4.08,0,0,1-1.78.07,3.94,3.94,0,0,0,3.69,2.74A7.89,7.89,0,0,1,125,140.21Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "134.65px 134.237px",
            }}
            id="el88xsw81brx"
            className="animable"
          />
          <circle
            cx="345.06"
            cy="310.12"
            r="18.28"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "345.06px 310.12px",
            }}
            id="elg3k9kx240e"
            className="animable"
          />
          <path
            d="M356.78,308.76a3.51,3.51,0,0,1,.54,0,2,2,0,1,0,0-4.07h0v0h-7.71s.47-2.78.47-3.73.48-2.39-.79-4.22-4.21-1.11-4.21-1.11l.44,3.78a2.94,2.94,0,0,1-.32,1.72l-3.46,6.5-1.59,1.36-.48,8c1,2.46,4.29,3.26,4.29,3.26h10.43a2.11,2.11,0,0,0,2.16-2,2,2,0,0,0-1-1.71,1.75,1.75,0,0,0,.4,0,2.1,2.1,0,0,0,2.16-2,2,2,0,0,0-1-1.69,2.07,2.07,0,0,0,1.79-2A2.1,2.1,0,0,0,356.78,308.76Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "349.513px 307.871px",
            }}
            id="elhrcnlmu1d8"
            className="animable"
          />
          <path
            d="M354.37,320.55h-10.5c-.14,0-3.43-.87-4.51-3.45a.35.35,0,0,1,0-.14l.48-8a.3.3,0,0,1,.11-.23l1.54-1.31,3.44-6.45a2.64,2.64,0,0,0,.29-1.54l-.45-3.77a.32.32,0,0,1,.24-.35c.13,0,3.17-.74,4.55,1.24a5.17,5.17,0,0,1,.89,3.81c0,.21,0,.41,0,.59a31.41,31.41,0,0,1-.42,3.41h7.34l.11,0a2.42,2.42,0,0,1,2.38,2.36,2.36,2.36,0,0,1-1.56,2.19,2.31,2.31,0,0,1,1,1.91,2.34,2.34,0,0,1-1.45,2.14,2.28,2.28,0,0,1,.62,1.55,2.4,2.4,0,0,1-2.06,2.32,2.25,2.25,0,0,1,.48,1.39A2.42,2.42,0,0,1,354.37,320.55ZM344,319.91h10.39a1.79,1.79,0,0,0,1.84-1.72,1.7,1.7,0,0,0-.83-1.44.31.31,0,0,1-.13-.38.31.31,0,0,1,.35-.2l.35,0a1.79,1.79,0,0,0,1.84-1.72,1.68,1.68,0,0,0-.81-1.42.31.31,0,0,1-.14-.33.32.32,0,0,1,.26-.25,1.76,1.76,0,0,0,1.52-1.69,1.78,1.78,0,0,0-1.84-1.72h0a.32.32,0,0,1-.3-.32c0-.35.29-.35.87-.35a1.72,1.72,0,1,0,0-3.43h0l-.11,0h-7.6a.34.34,0,0,1-.25-.11.35.35,0,0,1-.07-.26,36,36,0,0,0,.48-3.68c0-.21,0-.42,0-.65a4.54,4.54,0,0,0-.78-3.39c-.92-1.31-2.88-1.15-3.6-1l.41,3.48a3.25,3.25,0,0,1-.36,1.91l-3.46,6.5a.39.39,0,0,1-.08.1l-1.48,1.26-.47,7.82C340.91,319,343.64,319.82,344,319.91Z"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "349.606px 307.863px",
            }}
            id="elxillaq8aff"
            className="animable"
          />
          <rect
            x="332.68"
            y="305.99"
            width="8.4"
            height="15.5"
            rx="2.14"
            style={{
              fill: "rgb(146, 227, 169)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "336.88px 313.74px",
            }}
            id="el5nmzt3oa1jy"
            className="animable"
          />
          <path
            d="M370.12,129.2a14.21,14.21,0,0,0-14.21,14.21c0,7.85,14.21,24.92,14.21,24.92s14.21-17.07,14.21-24.92A14.21,14.21,0,0,0,370.12,129.2Zm0,22.14a7.52,7.52,0,1,1,7.52-7.52A7.52,7.52,0,0,1,370.12,151.34Z"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "370.12px 148.765px",
            }}
            id="elvfsg6uluuwn"
            className="animable"
          />
          <polygon
            points="403.29 225.24 403.36 225.19 403.22 225.19 380.81 208.34 358.39 225.19 358.25 225.19 358.32 225.24 358.25 225.29 358.4 225.29 381.01 241.56 403.22 225.29 403.36 225.29 403.29 225.24"
            style={{
              fill: "rgb(146, 227, 169)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "380.805px 224.95px",
            }}
            id="el8wgse3ntov4"
            className="animable"
          />
          <rect
            x="365.32"
            y="210.02"
            width="30.26"
            height="37.56"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(146, 227, 169)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "380.45px 228.8px",
            }}
            id="elu83u5pmgt5q"
            className="animable"
          />
          <path
            d="M386.9,220.24c0,4.36-2,6.86-4,6.86a1.53,1.53,0,0,1-1.74-1.49,2.32,2.32,0,0,1-2.26,1.49c-1.65,0-2.22-1.41-2-3.36l.24-2.2c.19-2,1.1-3.25,2.72-3.21a1.83,1.83,0,0,1,1.89,1.45l.16-1.36h1.49l-.66,6.31c-.07.5.09.94.64.94,1.69,0,2.26-3.23,2.24-5.38,0-3.06-1.43-5-4.73-5-4.39,0-6.43,3.64-6.43,8.57,0,3.8,1.73,6.08,5.56,6.08a6.58,6.58,0,0,0,4.32-1.38l-.13,1.52a7.16,7.16,0,0,1-4.28,1.18c-4.84,0-6.95-3-6.95-7.49,0-5.23,2.51-9.82,8-9.82C385.32,213.92,386.9,216.62,386.9,220.24Zm-8.33,1.45-.2,2c-.13,1.15.22,2,1.25,2s1.56-.77,1.7-2l.22-2.11c.11-1-.31-1.75-1.28-1.75S378.7,220.51,378.57,221.69Z"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "379.94px 222.609px",
            }}
            id="elaee5zj047y"
            className="animable"
          />
          <polygon
            points="358.25 225.19 358.25 251.34 403.36 251.34 403.36 225.19 380.81 238.26 358.25 225.19"
            style={{
              fill: "rgb(146, 227, 169)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "380.805px 238.265px",
            }}
            id="elx3un1xhctam"
            className="animable"
          />
          <polyline
            points="358.25 251.34 380.81 238.26 403.36 251.34"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "380.805px 244.8px",
            }}
            id="eliwryqmiklgm"
            className="animable"
          />
          <rect
            x="190.25"
            y="80.27"
            width="47.89"
            height="33.21"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "214.195px 96.875px",
            }}
            id="el48yevbgr59"
            className="animable"
          />
          <g id="elkuz14m2ojsf">
            <circle
              cx="214.2"
              cy="96.88"
              r="10.98"
              style={{
                fill: "rgb(146, 227, 169)",
                transformOrigin: "214.2px 96.88px",
                transform: "rotate(-13.35deg)",
              }}
              className="animable"
            />
          </g>
          <polygon
            points="222.16 97.08 208.74 89.33 208.74 104.83 222.16 97.08"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "215.45px 97.08px",
            }}
            id="el2q5oedp5elt"
            className="animable"
          />
          <line
            x1="191.31"
            y1="110.15"
            x2="236.84"
            y2="110.15"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "214.075px 110.15px",
            }}
            id="elgzicv0e446g"
            className="animable"
          />
          <path
            d="M139.37,229.6A11.45,11.45,0,0,0,121.76,218,15.44,15.44,0,0,0,92,223.85c0,.19,0,.38,0,.56A10.05,10.05,0,0,0,93.43,244v.11h45.45a7.28,7.28,0,0,0,.49-14.55Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(146, 227, 169)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "115.542px 226.222px",
            }}
            id="elkmz2f5uttef"
            className="animable"
          />
          <polygon
            points="126.41 239.49 113.81 223.64 101.22 239.49 106.14 239.49 106.14 253.76 121.48 253.76 121.48 239.49 126.41 239.49"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "113.815px 238.7px",
            }}
            id="el6l2lae303dc"
            className="animable"
          />
        </g>
        <g
          id="freepik--Character--inject-348"
          className="animable"
          style={{ transformOrigin: "234.715px 292.839px" }}
        >
          <path
            d="M219.61,420.56a53.09,53.09,0,0,0-4.47,3.91c-2,2-7.76,4.29-11.23,6.74s-1.43,4.9,1.63,5.92,11.85.2,15.11-.41,4.91-4.49,7.56-5.1,7.56,1.63,7.76-2.25.41-7.76.41-7.76Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "219.237px 429.086px",
            }}
            id="eleftqtd3foqp"
            className="animable"
          />
          <path
            d="M236,429.37c0-.79.08-1.57.12-2.33-2.22,1.6-5,1.53-8.7,2.33-4.69,1-6.33,5.51-15.72,5.92-6.08.26-8.55-.84-9.55-1.65-.17,1.51,1.4,2.81,3.42,3.49,3.07,1,11.85.2,15.11-.41s4.91-4.49,7.56-5.1S235.77,433.25,236,429.37Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "219.129px 432.322px",
            }}
            id="elooju01rrc5n"
            className="animable"
          />
          <path
            d="M221.47,419.77s-4.7,5.72-6.33,7.56,3.06,1.22,5.11-.62a60.08,60.08,0,0,0,5.31-6.12Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "220.181px 424.104px",
            }}
            id="elcr1p48b83n"
            className="animable"
          />
          <path
            d="M285.84,420.56a53.09,53.09,0,0,1,4.47,3.91c2,2,7.76,4.29,11.23,6.74s1.43,4.9-1.63,5.92-11.85.2-15.11-.41-4.91-4.49-7.56-5.1-7.56,1.63-7.76-2.25-.41-7.76-.41-7.76Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "286.213px 429.086px",
            }}
            id="elc5ti3mo1mf"
            className="animable"
          />
          <path
            d="M269.48,429.37c0-.79-.08-1.57-.12-2.33,2.22,1.6,5,1.53,8.7,2.33,4.69,1,6.33,5.51,15.72,5.92,6.08.26,8.55-.84,9.55-1.65.17,1.51-1.4,2.81-3.42,3.49-3.07,1-11.85.2-15.11-.41s-4.91-4.49-7.56-5.1S269.68,433.25,269.48,429.37Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "286.351px 432.322px",
            }}
            id="eldempxvd3gz7"
            className="animable"
          />
          <path
            d="M284,419.77s4.7,5.72,6.33,7.56-3.06,1.22-5.11-.62a60.08,60.08,0,0,1-5.31-6.12Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "285.289px 424.104px",
            }}
            id="el2y5v05uaivu"
            className="animable"
          />

          <path
            d="M222.16,274.66s-2.55,23-2.55,26.67,0,50.49.57,58.77-2.28,11.68-.57,22.23,2.28,29.1,2.28,29.1-2.28,1.43-2.28,2.86v6.27l17.18,1.87s1.08-14.7,1.36-19.27,4.57-33.36,5.71-41.64,5.64-55.38,5.64-55.38,7.48,52.25,8.05,54a105.15,105.15,0,0,1,4,12.53c2.56,9.42,4.85,41.94,4.85,41.94l2.45,9.08,17.56-3.27s-2.61-14.65-2.61-16.93-.57-1.43-1.14-7.14a222.6,222.6,0,0,1-.86-31.93c.57-16.55-7.13-60.48-7.13-60.48l-2.28-29.39S244.11,281.09,222.16,274.66Z"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "252.733px 349.12px",
            }}
            id="el2m4kuw4ce7a"
            className="animable"
          />
          <path
            d="M256.45,299l-4.62,8.58s9.9-5.94,16.17-6.93"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "259.915px 303.29px",
            }}
            id="elub9e39wg8u"
            className="animable"
          />
          <path
            d="M241.17,304.55s-4.3-6.94-11.89-8.92"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "235.225px 300.09px",
            }}
            id="eln5tbvximpmj"
            className="animable"
          />
          <path
            d="M221.31,277.08s-3,7.45-1.49,9.69,15.29,7.83,29.08,7.83,27.23-4.85,27.23-8.2-2.61-11.56-2.61-11.56S251.14,285.28,221.31,277.08Z"
            style={{
              fill: "rgb(146, 227, 169)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "247.763px 284.72px",
            }}
            id="el2fkr38j9chr"
            className="animable"
          />
          <path
            d="M225.86,206.08s-10,0-12.28,1.85-2.54,9.5-2.78,10.89-5.51,26.27-5.51,26.27l11.13,15.31,4.12-19.56.4,38s.75,3.74,3.3,5.13,20.39,2.78,28.73,2.78,20.36-4.18,21.29-6.73S272.44,249,272.44,249l3.95,8.79,9.48-16.24s-6.48-21.52-7.41-26.15-3.47-6-8.57-8.11-20.16-1.39-26.18-1.39S225.86,206.08,225.86,206.08Z"
            style={{
              fill: "rgb(146, 227, 169)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "245.58px 246.266px",
            }}
            id="elfj56oryz1lj"
            className="animable"
          />
          <path
            d="M228.39,280.81a8.6,8.6,0,0,0,7.46-7.09c1.49-6.71,2.24-10.44,2.24-10.44l2.61-.37a105.75,105.75,0,0,1-.75,11.18c-.74,6.34-3,8.21-4.1,8.21"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "234.545px 272.605px",
            }}
            id="elgx4atgsuyx"
            className="animable"
          />
          <path
            d="M269.79,277.45c3-7.46-3.73-16-3.73-16l-2.61-.37s7.46,9.7,3.73,17.15"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "267.005px 269.655px",
            }}
            id="eljrludkp51wl"
            className="animable"
          />
          <line
            x1="220.54"
            y1="240.84"
            x2="218.91"
            y2="229.02"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "219.725px 234.93px",
            }}
            id="elnc7h0o9cs7"
            className="animable"
          />
          <line
            x1="272.44"
            y1="248.95"
            x2="271.51"
            y2="227.4"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "271.975px 238.175px",
            }}
            id="elr7y070ij0d"
            className="animable"
          />
          <path
            d="M274.35,250.24s-2.89-5.36,6.6-9.9"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "277.443px 245.29px",
            }}
            id="eludq9hdmeq2s"
            className="animable"
          />
          <path
            d="M276,239.1S281,237,284.25,237"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "280.125px 238.05px",
            }}
            id="el71dy7u8q47r"
            className="animable"
          />
          <path
            d="M209.15,240.34s7.84,2.48,9.49,8.67"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "213.895px 244.675px",
            }}
            id="elm650ctly4s"
            className="animable"
          />
          <path
            d="M218.23,243.23s-2.89-4.95-7.43-5.78"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "214.515px 240.34px",
            }}
            id="elboa4xnmjdlw"
            className="animable"
          />
          <path
            d="M240.3,195.67s-5.26,0-6.81,1.17-2.34,4.28-3.12,4.86-4.28.39-5.06,2.53-.78,1.56,5.26,7.21,21,12.07,22.58,12.07,11.68-11.68,14.6-13,3.31-1.36,3.5-2.14-.19-4.67-2.73-6.23a16.58,16.58,0,0,0-5.84-1.94s-.38-2.34-3.5-4.09-7.4-2.14-10.51-2.14S240.3,195.67,240.3,195.67Z"
            style={{
              fill: "rgb(146, 227, 169)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "248.122px 208.74px",
            }}
            id="elbwtz5nv0ke"
            className="animable"
          />
          <path
            d="M262.68,200.15a31.66,31.66,0,0,1-1.95,8.42"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "261.705px 204.36px",
            }}
            id="elsnglh3yfzc"
            className="animable"
          />
          <path
            d="M231.44,204.44s.82,2.48,5.36,4.95"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "234.12px 206.915px",
            }}
            id="elktez1uarh2"
            className="animable"
          />
          <line
            x1="249.5"
            y1="220.68"
            x2="249.96"
            y2="246.17"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "249.73px 233.425px",
            }}
            id="elwh9spb4jxpr"
            className="animable"
          />
          <line
            x1="255.06"
            y1="220.91"
            x2="257.38"
            y2="244.54"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "256.22px 232.725px",
            }}
            id="el1n9jq2ua6ir"
            className="animable"
          />
          <g id="elc4r6d2nzigm">
            <rect
              x="191.2"
              y="204.6"
              width="12.85"
              height="24.08"
              rx="1.53"
              style={{
                fill: "rgb(38, 50, 56)",
                stroke: "rgb(38, 50, 56)",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                transformOrigin: "197.625px 216.64px",
                transform: "rotate(-18.98deg)",
              }}
              className="animable"
            ></rect>
          </g>
          <path
            d="M197.69,237.2c-.81-.81-8.25-18.53-8.92-20.15s7.57-7.31,7.57-7.31,2.17-1.35,2.17-.13-4.2,4.87-4.74,5.82,1.22,3.92,1.22,3.92,5.27-2.84,5.68-2.84,6.22,5,6.76,5.54,2,2.17,1.63,3-1.36.41-2,.27-6.63,2-6.63,2,2.3,3.25,2,5.14a22.88,22.88,0,0,1-.81,3.52s12.84,15.77,14.46,16.72,2.54,4.41,1.46,8.06-3.62,3.43-7,3.3S197.69,237.2,197.69,237.2Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "203.369px 236.608px",
            }}
            id="elxf6uh3n4vg9"
            className="animable"
          />
          <path
            d="M202.83,219.08a8.44,8.44,0,0,0-2.84.4c-.54.41-4.59,3.11-4.59,3.11"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "199.115px 220.833px",
            }}
            id="elvpznjtexvoq"
            className="animable"
          />
          <path
            d="M196.88,224.89a46.46,46.46,0,0,1,4.6-3,38.36,38.36,0,0,1,5.14-.27"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "201.75px 223.253px",
            }}
            id="elrtfzspbalwj"
            className="animable"
          />
          <path
            d="M207.84,222.73s-6,1.22-6.76,1.49a16.07,16.07,0,0,0-3,2.3"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "202.96px 224.625px",
            }}
            id="el9mz424x7y0o"
            className="animable"
          />
          <line
            x1="194.99"
            y1="219.35"
            x2="193.23"
            y2="220.43"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "194.11px 219.89px",
            }}
            id="elal7jkz6unnk"
            className="animable"
          />
          <path
            d="M285.15,242.63l22.28-17.71s-1-6.37-.8-7.36,2-6.76,6.37-8.95,5.77-2.59,5.77-1-1.6,2-2,2.59-2.59,2.78-2.59,2.78,4.18-1.79,5.57-1.79,3.78-.59,3.78.8-1.59,1.39-2.59,1.39-3.78,2.79-3.78,2.79a70.24,70.24,0,0,1,7-1.4c1.19,0,2.19,1.4,1.19,2a11.92,11.92,0,0,1-2.78,1,26.13,26.13,0,0,0-2.59,1.39s6-.6,5.57,1-3.18,1.19-3.78,1.19a6.62,6.62,0,0,0-3.38,2c-.4.79-1.2,4.18-2.79,5s-4.57,2-4.57,2-10.15,12.34-14.33,17.11S285,257.74,280.77,258.74s-5-.2-5.17-2S275.58,248.59,285.15,242.63Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "300.664px 232.873px",
            }}
            id="eld3fnz3gswoa"
            className="animable"
          />
          <path
            d="M307.63,215s4.77-2.18,5.76-2.18,2.59.79,1.4,2.18a25.88,25.88,0,0,1-4.38,3.39s5.77,2.38,3,6.76"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "311.421px 218.985px",
            }}
            id="el0qxls8f8lbhg"
            className="animable"
          />
          <path
            d="M251.42,161.49s-6.24,1.09-9.12,4.61S229.94,190.91,230,192.82s9.63.94,9.63.94,1,10.66,4.37,18.46c.93,2.12,4.24,6.32,7.79,4.75s5.09-25.88,5.09-25.88,3.38,4.79,5.94,3.51c2.92-1.47,2.39-5,.3-5.26s6.7-8,4.53-13.94S254.12,159.85,251.42,161.49Z"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "248.999px 189.309px",
            }}
            id="el5ycotb9kpgl"
            className="animable"
          />
          <path
            d="M249.77,154.56S238,153.42,236.85,159s4.66,8.7,11.46,8.3,12.84.62,13.58,1.75-8.48,2.35-6.53,4a6.81,6.81,0,0,0,4.07,1.66s-3,.55-3,2.67.93,11.55,2.4,11.69,4.27.23,4.27.23a27,27,0,0,0,7.63-4.54c3.47-3.05,5-10.09,1.43-14.67s-6.32-8.93-9.14-12.58-5.4-7.51-11.6-9S241.6,149.33,249.77,154.56Z"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "255.451px 168.682px",
            }}
            id="elfqwa3l9tdk"
            className="animable"
          />
          <path
            d="M272.12,178.58a13.43,13.43,0,0,1-.23,3.73"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "272.025px 180.445px",
            }}
            id="elmouczco7k7"
            className="animable"
          />
          <path
            d="M252.05,161.47s-13,0-8.36,2.92,12.51-.94,21.51,3.68a12.08,12.08,0,0,1,6.38,7.43"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "257.135px 168.485px",
            }}
            id="eld4h55b3u9ef"
            className="animable"
          />
          <path
            d="M258.91,172s8.36,5.15,8.92,11.51"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "263.37px 177.755px",
            }}
            id="ele9jxsnu5yfw"
            className="animable"
          />
          <path
            d="M239.6,193.76s10.12.35,13.07-1.69"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "246.135px 192.926px",
            }}
            id="elylesevrhs6n"
            className="animable"
          />
          <path
            d="M237.28,185.07s7.13,2.81,9.65-1.38"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "242.105px 184.841px",
            }}
            id="elpb2f5p4r399"
            className="animable"
          />
          <line
            x1="245.75"
            y1="182.73"
            x2="248.77"
            y2="184.29"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "247.26px 183.51px",
            }}
            id="elu0e0cjn9cah"
            className="animable"
          />
          <path
            d="M240.52,175.66s-6.58.2-6.68,1.25,3.8,4.39,3.8,4.39"
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "237.179px 178.48px",
            }}
            id="elo1rd2ehb84"
            className="animable"
          />
          <path
            d="M241.54,173.73c-.39.87-1.06,1.43-1.5,1.23s-.48-1.06-.08-1.94,1.06-1.43,1.5-1.23S241.93,172.85,241.54,173.73Z"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "240.748px 173.375px",
            }}
            id="elfzpy5r3vgs"
            className="animable"
          />
          <path
            d="M246.78,177.06c-.39.88-1.07,1.43-1.5,1.23s-.48-1.06-.09-1.94,1.06-1.43,1.5-1.23S247.17,176.18,246.78,177.06Z"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "245.987px 176.705px",
            }}
            id="elwwca83y1tul"
            className="animable"
          />
          <path
            d="M247.66,171.14s3.15.59,2.44,4.89Z"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "248.932px 173.585px",
            }}
            id="elq4xmezaq1na"
            className="animable"
          />
          <path
            d="M245,168.84s-1.51-2.48-4.13.47Z"
            style={{
              fill: "rgb(38, 50, 56)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "242.935px 168.587px",
            }}
            id="ela50fd7ruom"
            className="animable"
          />
          <path
            d="M192.05,201.64l-.16-7.12a3.57,3.57,0,0,0,3.56-3.55V171.6a3.57,3.57,0,0,0-3.56-3.56H147.22a3.57,3.57,0,0,0-3.55,3.56V191a3.56,3.56,0,0,0,3.55,3.55h38.63Z"
            style={{
              fill: "rgb(38, 50, 56)",
              transformOrigin: "169.56px 184.84px",
            }}
            id="elylxe0hse2b"
            className="animable"
          />
          <path
            d="M158.52,180.49a2.18,2.18,0,1,0-2.18,2.17A2.18,2.18,0,0,0,158.52,180.49Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "156.34px 180.48px",
            }}
            id="el072u3hfoegio"
            className="animable"
          />
          <path
            d="M171.36,180.49a2.18,2.18,0,1,0-2.17,2.17A2.17,2.17,0,0,0,171.36,180.49Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "169.18px 180.48px",
            }}
            id="elhpewfmuc6x"
            className="animable"
          />
          <path
            d="M184.21,180.49a2.18,2.18,0,1,0-2.18,2.17A2.18,2.18,0,0,0,184.21,180.49Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "182.03px 180.48px",
            }}
            id="el0ed33e5b63ev"
            className="animable"
          />
        </g>
        <defs>
          {" "}
          <filter id="active" height="200%">
            {" "}
            <feMorphology
              in="SourceAlpha"
              result="DILATED"
              operator="dilate"
              radius="2"
            ></feMorphology>{" "}
            <feFlood
              flood-color="#32DFEC"
              flood-opacity="1"
              result="PINK"
            ></feFlood>{" "}
            <feComposite
              in="PINK"
              in2="DILATED"
              operator="in"
              result="OUTLINE"
            ></feComposite>{" "}
            <feMerge>
              {" "}
              <feMergeNode in="OUTLINE"></feMergeNode>{" "}
              <feMergeNode in="SourceGraphic"></feMergeNode>{" "}
            </feMerge>{" "}
          </filter>{" "}
          <filter id="hover" height="200%">
            {" "}
            <feMorphology
              in="SourceAlpha"
              result="DILATED"
              operator="dilate"
              radius="2"
            ></feMorphology>{" "}
            <feFlood
              flood-color="#ff0000"
              flood-opacity="0.5"
              result="PINK"
            ></feFlood>{" "}
            <feComposite
              in="PINK"
              in2="DILATED"
              operator="in"
              result="OUTLINE"
            ></feComposite>{" "}
            <feMerge>
              {" "}
              <feMergeNode in="OUTLINE"></feMergeNode>{" "}
              <feMergeNode in="SourceGraphic"></feMergeNode>{" "}
            </feMerge>{" "}
            <feColorMatrix
              type="matrix"
              values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
            ></feColorMatrix>{" "}
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default SuccessAnimation;
