import React from 'react';
import './Footer.css';

function Footer() {
  const handleClick = () => {
    window.open('https://storyset.com', '_blank', 'noopener,noreferrer');
  };

  return (
    <footer className="footer" onClick={handleClick}>
      Illustrations by Storyset
    </footer>
  );
}

export default Footer;
